import { get } from '@/utils/http'

export function getFinanceList(page, filter=null) {
    return get({
        url: 'admin/finance',
        method: 'get',
        params: {
            page: page,
            ...filter
        }
    })
}

export function queryPaidStatus(out_trade_no) {
    return get({
        url: 'admin/finance/queryPaidStatus',
        method: 'get',
        params: {
            out_trade_no
        }
    })
}