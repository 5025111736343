<template>
    <elForm>
            <div v-if="row.log_form.former.former.content.submitType === 'content'" class="content">
                <el-form-item label="论文内容">
                    <el-input
                            type="textarea"
                            :rows="15"
                            :disabled="true"
                            v-model="row.log_form.former['former']['content']['content']">
                    </el-input>
                </el-form-item>
            </div>

<!--            <div class="download" v-if="row.log_form.former.content.submitType === 'file'">-->
<!--                <span>{{file}}[{{row.log_form.former}}]</span>-->
<!--                <el-button type="primary" icon="el-icon-download" size="small" @click="handleDownloadFile" circle></el-button>-->
<!--            </div>-->
        <div class="download" v-if="row.log_form.former.former.content.submitType === 'file'">
            <span>{{file}}[{{row.log_form.former.former.content.contentWords}}]</span>
            <el-button type="primary" icon="el-icon-download" size="small" @click="handleDownloadFile" circle></el-button>
        </div>
    </elForm>
</template>

<script>
    export default {
        name: "FileType",
        props: ['row', 'ex'],
        mounted() {
        },
        computed:{
            // eslint-disable-next-line vue/return-in-computed-property
            file(){
                if(this.row.paper_order){
                    return this.row.paper_order.file.former
                }else{
                    return this.row.file_item.name;
                }
            }
        },
        methods:{
            //下载文件
            handleDownloadFile(){
                const file = 'http://test2.zjhengde.com/uploads/paper/'+this.file.name;
                window.open(file, '_blank')
            },
        }
    }
</script>

<style scoped>

</style>