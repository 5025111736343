<template>
    <div class="finance">
        <el-row class="filter-container" :gutter="10" style="margin-bottom: .5rem;">
            <el-col :span="24">
                <el-button type="default"
                           style="text-align:left;border: none;font-size: 12px;color: blue;margin-top: .5rem;width: 100%">
                    当天总成交额: {{todayMoney}} 当天总成本: {{todayCost}} 当天总利润: {{todayProfit}}
                    <span style="float: right">{{ today }}</span>
                </el-button>
            </el-col>
            <el-col :md="4" :lg="4" :sm="24">
                <el-input v-model="listQuery.out_trade_no" style="width: 100%;margin-top: .5rem" placeholder="订单号"
                          class="filter-item" @keyup.enter.native="handleFilter"/>
            </el-col>
            <el-col :md="4" :lg="3" :sm="24">
                <el-select v-model="listQuery.type" style="width: 100%;margin-top: .5rem" placeholder="支付方式" clearable
                           class="filter-item">
                    <el-option v-for="item in paymentType" :key="item.name" :label="item.name+'('+item.value+')'"
                               :value="item.value"/>
                </el-select>
            </el-col>
            <el-col :md="4" :lg="3" :sm="24">
                <el-select v-model="listQuery.status" style="width: 100%;margin-top: .5rem" placeholder="订单状态" clearable
                           class="filter-item" @change="handleFilter">
                    <el-option v-for="item in statusOptions" :key="item.name" :label="item.name" :value="item.value"/>
                </el-select>
            </el-col>
            <el-col :md="12" :lg="6" :sm="24">
                <el-date-picker
                        style="width: 100%;margin-top: .5rem"
                        v-model="listQuery.date"
                        type="date"
                        range-separator="至 "
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        class="filter-item"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-col>

            <el-col :md="8" :lg="3" :sm="24">
                <el-button :loading="loading" style="margin-top: .5rem" class="filter-item" type="primary"
                           icon="el-icon-search" @click="handleFilter">
                    搜索
                </el-button>
            </el-col>
        </el-row>

        <el-table
                v-loading="bxLoading"
                :data="finances"
                style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="{row}">
                    <div v-if="row.log_form">
                        <form-view :form="row"/>
                        <file-type :row="row" :ex="'nex'"/>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="编号/ID" prop="id"></el-table-column>
            <el-table-column prop="out_trade_no" label="订单号" width="170"></el-table-column>
            <el-table-column prop="paper.name" label="下单业务"></el-table-column>
            <el-table-column prop="paper.name" label="成本价/字数">
                <template slot-scope="{row}">
                    <span v-if="row.paper"> {{ row.paper.cost_money || 0 }} / {{ row.paper.cost_num }}</span>
                    <span v-else>错误提交</span>
                </template>
            </el-table-column>
            <el-table-column prop="pay_type" label="支付方式"></el-table-column>
            <el-table-column label="下单金额/下单字数/件数">
                <template slot-scope="{row}">
                    <el-tag type="primary">￥
                        <!--                        {{ row.form !== "" ? row.former['former']['content']['total'] :-->
                        <!--                        row.former['former']['former']['content']['total']}}-->
                        {{ row.former !== null ? row.former['former']['content']['total'] : (row.log_form ?
                        row.log_form['former']['former']['content']['total'] : '-' ) }} / {{ row.former !== null ?
                        row.former['former']['content']['contentWords'] : (row.log_form ?
                        row.log_form['former']['former']['content']['contentWords'] : '-' ) }} / {{ row.former !== null
                        ? row.former['former']['content']['buyerNum'] : (row.log_form ?
                        row.log_form['former']['former']['content']['buyerNum'] : '-' ) }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="下单状态" width="160">
                <template slot-scope="{row}">
                    <el-tag v-if="row.paper_order" :type="row.paper_order.statuses.type">
                        {{row.paper_order.statuses.string}}
                    </el-tag>
                    <el-tag v-else type="danger">未支付, 或支付失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="提交时间" width="160">
                <template slot-scope="{row}">
                    {{row.created_at ? row.created_at : '空' }}
                </template>
            </el-table-column>
            <el-table-column
                    label="ip"
                    width="105"
            >
                <template slot-scope="scope">
                    {{ scope.row.log_form.ip }}
                </template>
            </el-table-column>
            <el-table-column
                label="浏览器信息"
                width="105"
            >
                <template slot-scope="scope">
                    {{ scope.row.log_form.agent }}
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary"
                               size="mini"
                               v-if="!scope.row.paper_order"
                               :data-id="scope.row.id"
                               @click="budanHandler(scope.row)"
                               >补单</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="tips">
            <span class="red">*提交记录</span>
        </div>
        <pagination
                :hiddenSingle="false"
                :meta="meta"
                :layout="'prev, pager, next'"
                v-on:pagination:switched="handleSwitchPage"
        />
        <el-dialog title="输入密码进行补单" :visible.sync="dialogBudanVisible">
          <el-form :model="form">
            <el-form-item label="活动名称" :label-width="formLabelWidth">
              <el-input v-model="budan.pwd" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="requestBudan">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getFinanceList} from '@/api/finance'
    import Pagination from "../../components/pagination/Pagination";
    import FileType from "./FileType";
    import FormView from "./components/FormView";

    export default {
        name: "financeList",
        data() {
            return {
                dialogBudanVisible:false,
                budan: {
                    pwd: ''
                },
                finances: [],
                listQuery: {
                    out_trade_no: '',
                    type: null,
                    status: null,
                    date: null
                },
                paymentType: [
                    {name: '微信支付', value: 'wxpay'},
                    {name: '支付宝支付', value: 'alipay'},
                    {name: '淘宝支付', value: 'tbpay'} // 淘宝下单的订单号
                ],
                statusOptions: [
                    {name: '已完成', value: 2},
                    {name: '已支付,待检测', value: 1},
                    {name: '未支付', value: 0},
                ],
                loading: false,
                bxLoading: true,
                meta: [],
                todayMoney: 0,
                todayCost: 0,
                todayProfit: 0,
                today:'',
            }
        },
        watch: {
            "$route": function () {
                this.handleGetFinance()
            }
        },
        mounted() {
            this.handleGetFinance()
        },
        computed: {},
        methods: {
            budanHandler() {
              console.log('budan') 
              this.dialogBudanVisible = true
            },
            requestBudan() {
                
            },
            async handleGetFinance(page = 1) {
                const rsp = await getFinanceList(page);
                console.log(rsp)
                this.finances = rsp.data;
                this.meta = rsp.meta;
                this.todayMoney = rsp.todayMoney;
                this.todayCost = rsp.todayCostMoney;
                this.todayProfit = rsp.todayProfit;
                this.today = rsp.today;
                this.bxLoading = false;
            },
            async handleFilter() {
                this.bxLoading = true;
                const rsp = await getFinanceList(this.meta.current_page, this.listQuery);
                this.finances = rsp.data;
                this.meta = rsp.meta;
                this.todayMoney = rsp.todayMoney;
                this.todayCost = rsp.todayCostMoney;
                this.todayProfit = rsp.todayProfit;
                this.today = rsp.today;
                this.bxLoading = false;
            },

            handleSwitchPage(page) {
                this.bxLoading = true;
                this.handleGetFinance(page, this.listQuery)
            }
        },
        components: {
            Pagination,
            FileType,
            FormView
        }
    }
</script>
<style scoped>
    .el-date-editor .el-range-separator {
        margin-right: 10px !important;
    }

    .download span {
        margin-right: 10px
    }
</style>
<style scoped>
    .finance {
        background: #fff;
        padding: 15px
    }

    .finance-page {
        display: flex;
        justify-content: center;
        margin-top: 15px
    }

    .filter-item {
        margin-right: 10px
    }

    .tips {
        font-size: 10px;
        color: red;
        margin-top: 10px
    }
</style>