<template>
    <el-form>
        <el-form-item label="论文标题">
            <span>{{ form.log_form.former['former']['title'] }}</span>
        </el-form-item>
        <el-form-item label="论文作者">
            <span>{{ form.log_form.former['former']['author'] }}</span>
        </el-form-item>
        <el-form-item label="论文字数">
            <span>{{ form.log_form.former['former']['content']['contentWords'] }}</span>
        </el-form-item>
        <el-form-item label="金额">
            <span>{{ form.log_form.former['former']['content']['total'] }}</span>
        </el-form-item>
        <hr>
        <el-form-item label="成本价/字数">
            <span>{{ form.paper.cost_money }} / {{ form.paper.cost_num }}</span>
        </el-form-item>
        <hr>
        <el-form-item label="发布时间">
            <span>{{ form.log_form.former['former']['publish_time'] || '-' }}</span>
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        name: "logform",
        props: ['form'],
        created() {
            console.log(this.form)
        }
    }
</script>

<style scoped>

</style>