<template>
    <div>
        <component :form="form" :is="form_type"/>
    </div>
</template>

<script>
    import logform from "./form/logform";
    import old from "./form/old";
    export default {
        name: "FormView",
        props: ['form'],
        components:{
            logform,
            old
        },
        computed:{
            form_type(){
                return this.form.log_form !== null ? 'logform'  : 'old';
            }
        }
    }
</script>

<style scoped>

</style>